var _hmt = _hmt || [];

function baidutongji(value){
  _hmt.push(['_trackPageview',"/"+value]);
}

function initBaiduTongJi(){
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?73656ee91299e9e839db68eef4b046ca";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
}

